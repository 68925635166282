import React, { createContext, createRef, Dispatch, SetStateAction, useEffect, useState } from "react";
import Home from "./Components/Home/Home";
import Header from "./Components/Shared/Header/Header";
import ReCAPTCHA from 'react-google-recaptcha';
import { Route, Routes } from "react-router-dom";
import Dash from "./Components/Dash/Dash";
import Buy from "./Components/Dash/Pages/Buy";
import Withdraw from "./Components/Dash/Pages/Withdraw";
import Affilates from "./Components/Dash/Pages/Affilates";
import Settings from "./Components/Dash/Pages/Settings";
import Banners from "./Components/Dash/Pages/Banners";
import Dashboard from "./Components/Dash/Pages/Dashboard";
import PayPending from "./Components/Dash/Pages/PayPending";
import Loading from "./utils/components/Loading";
import { getLsItems, handleAxiosError, removeLsItems, setLsItems } from "./utils/utils";
import { RequireUser } from "./utils/RequireUser";
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { User, UserTemplate } from "./types/User";

export interface UserContext {
    loading: boolean
    setLoading: CallableFunction
    user: User
    setUser: Dispatch<SetStateAction<User>>
    setUserLoading: CallableFunction
    recaptchaRef: ReCAPTCHA | null
}

export const MyContext = createContext<UserContext>({
    loading: false,
    user: UserTemplate,
    setUser: () => { },
    setLoading: () => { },
    setUserLoading: () => { },
    recaptchaRef: null,
});

function App() {

    const [loading, setLoading] = useState<boolean>(false);
    const [userLoading, setUserLoading] = useState<boolean>(true);
    const [menuExpanded, setMenuExpanded] = useState<boolean>(false);
    const [user, setUser] = useState<User>(UserTemplate);
    const recaptchaRef = createRef<ReCAPTCHA>();

    useEffect(() => console.log('loading', loading), [loading]);
    useEffect(() => console.log('userLoading', userLoading), [userLoading]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setUserLoading(true);
                const { jwt, wallet } = getLsItems();
                if (!jwt || jwt.length !== 50 || !wallet || wallet.length < 30 || wallet.length > 36) return removeLsItems();
                const r = await axios.get(`http://localhost:30/v1/users/${wallet}`, { headers: { jwt, wallet } });
                if (r.data?.user) {
                    setUser(r.data.user);
                    setLsItems(r.data.user.wallet, r.data.user.jwt);
                }
            }
            catch (error: any) { handleAxiosError(error, setUser); }
            finally { setUserLoading(false); setLoading(false); }
        })();
    }, []);

    const [hudai, setHudai] = useState(localStorage.getItem('okay'));
    const [searchParams] = useState(window.location.search);
    useEffect(() => {
        if (!hudai && searchParams.substring(0, 5) === '?pass') {
            setHudai('okay');
            localStorage.setItem('okay', 'okay');
        }
    }, [hudai, searchParams]);
    if (hudai !== 'okay' || userLoading) return <div></div>;

    if (loading) return <Loading />
    return (
        <MyContext.Provider value={{ loading, setLoading, user, setUser, setUserLoading, recaptchaRef: recaptchaRef.current }}>
            <ReCAPTCHA
                size='invisible'
                ref={recaptchaRef}
                sitekey='6LemfPojAAAAACo4eknsynmuXz4370x_xiE0rYy6'
                className='z-[9]'
            />
            <ToastContainer />
            <Header values={{ menuExpanded, setMenuExpanded }} />
            <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/dash" element={<Dash />}>
                    <Route index element={<RequireUser values={{ user, userLoading }}><Dashboard /></RequireUser>} />
                    <Route path="new" element={<RequireUser values={{ user, userLoading }}><Buy /></RequireUser>} />
                    <Route path="new/:id" element={<RequireUser values={{ user, userLoading }}><PayPending /></RequireUser>} />
                    <Route path="withdraw" element={<RequireUser values={{ user, userLoading }}><Withdraw /></RequireUser>} />
                    <Route path="affiliates" element={<RequireUser values={{ user, userLoading }}><Affilates /></RequireUser>} />
                    <Route path="settings" element={<RequireUser values={{ user, userLoading }}><Settings /></RequireUser>} />
                    <Route path="banners" element={<RequireUser values={{ user, userLoading }}><Banners /></RequireUser>} />
                </Route>

            </Routes>
        </MyContext.Provider>
    );
}

export default App;
