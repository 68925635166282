import React, { FC } from 'react';
import { SiVirustotal } from 'react-icons/si';
import { FiGift } from 'react-icons/fi';
import { HiOutlineUserGroup, HiOutlineUsers, HiUserCircle } from 'react-icons/hi';
import { AiOutlineClose } from 'react-icons/ai';
import { BsBookmarkCheck, BsFillInfoCircleFill } from 'react-icons/bs';
import { RxExternalLink } from 'react-icons/rx';
import { FaBitcoin } from 'react-icons/fa';
import { BiLink } from 'react-icons/bi';
import { MdOutlineFileCopy } from 'react-icons/md';

const Affilates:FC = () => {
    return (
        <div className='w-[1140px] max-w-[90%] mx-auto my-10'>
            <h2 className="text-3xl md:text-4xl font-light font-roboto tracking-wide text-center text-ph">Affiliate Program</h2>
            <p className="text-pt text-center">Your affiliate statistics</p>

            <div className="p-4 md:p-6 border border-gray-400/50 bg-white my-5 flex flex-wrap">
                <div className='flex jutsify-center items-center gap-2 w-full md:w-1/2 lg:w-1/4 my-2'>
                    <div className='bg-gray-400 rounded-full p-3'><HiOutlineUserGroup className='text-white text-3xl' /></div>
                    <div>
                        <p className='text-sm text-statsPrimary'>Affiliates</p>
                        <p className='text-statsSecond font-medium'>100</p>
                    </div>
                </div>
                <div className='flex jutsify-center items-center gap-2 w-full md:w-1/2 lg:w-1/4 my-2'>
                    <div className='bg-green-400 rounded-full p-3'><BsBookmarkCheck className='text-white text-3xl' /></div>
                    <div>
                        <p className='text-sm text-statsPrimary'>Active</p>
                        <p className='text-statsSecond font-medium'>10</p>
                    </div>
                </div>
                <div className='flex jutsify-center items-center gap-2 w-full md:w-1/2 lg:w-1/4 my-2'>
                    <div className='bg-yellow-500 rounded-full p-3'><SiVirustotal className='text-white text-3xl' /></div>
                    <div>
                        <p className='text-sm text-statsPrimary'>Affiliate Deposits</p>
                        <p className='text-statsSecond font-medium'>0.00000000 BTC</p>
                    </div>
                </div>
                <div className='flex jutsify-center items-center gap-2 w-full md:w-1/2 lg:w-1/4 my-2'>
                    <div className='bg-green-500 rounded-full p-3'><FiGift className='text-white text-3xl' /></div>
                    <div>
                        <p className='text-sm text-statsPrimary'>Your Bonus</p>
                        <p className='text-statsSecond font-medium'>0.00000000 BTC</p>
                    </div>
                </div>
            </div>

            <div className="bg-warn rounded py-4 px-4 md:px-6 flex justify-between items-center">
                <div className="text-white">
                    <div className='flex text-sm font-medium'>RULES & CONDITIONS <BsFillInfoCircleFill className='mt-[2px] ml-2' /></div>
                    <div>
                        <div className='flex gap-2'><BsFillInfoCircleFill className='mt-[3px]' /> <p className=''>We do not accept referral with same ip</p></div>
                        <div className='flex gap-2'><BsFillInfoCircleFill className='mt-[3px]' /> <p className=''>We do not Accept Invalid user Or Bots</p></div>
                    </div>
                </div>
                <AiOutlineClose
                    onClick={(e: any) => e.target.parentNode.remove()}
                    className='bg-red-500 text-white text-3xl rounded-full p-1 cursor-pointer'
                />
            </div>
            <div className='my-10 flex flex-col lg:flex-row w-full gap-5'>
                <div className='rounded-lg lg:w-1/3 bg-white'>
                    <div className='rounded-lg rounded-b-none bg-[#235fa7] p-[10px] flex items-center gap-3 px-5'>
                        <HiOutlineUsers className='text-white text-xl' /> <p className='text-white font-tello font-medium'>Referral Stats</p>
                    </div>
                    <div className="m-5 lg:m-8 relative">
                        <div className='flex justify-between items-center mb-3'>
                            <p className='font-bold text-[#253992]'>Referral URL</p>
                        </div>
                        <input type="text" inputMode='decimal'
                            className='w-full border border-[#d2dde9] rounded-[4px] h-12 duration-300 flex pr-[50px] pl-[40px] outline-none text-[#495463]'
                            value={`https://firemine.com/r/Rg53Fd5He3`} readOnly />
                        <span className='absolute top-[42px] text-[13px] right-[6px] group bg-[#e9eff9] font-medium p-2 hover:bg-[#2c80ff] duration-300 cursor-pointer rounded'>
                            <MdOutlineFileCopy className='text-[#758698] group-hover:text-white text-xl' />
                        </span>
                        <span className='absolute top-[42px] text-[13px] left-[6px] font-medium p-2'>
                            <BiLink className='text-[#495463] group-hover:text-white text-xl' />
                        </span>
                        <p className='text-[#253992] my-4 text-md font-medium'>Current Stats</p>

                        <p className='tracking-widest text-[#758698] text-[12px] font-medium'>AFFILIATE COMMISIONS</p>
                        <div className='flex items-center gap-2'><FaBitcoin className='text-2xl text-[#ed9633]' /> <span className='text-[#495463] font-medium'>0.00000000 BTC</span></div>
                        <hr className="mt-3 mb-5" />
                        <p className='tracking-widest text-[#758698] text-[12px] font-medium'>LAST REFERRED USER</p>
                        <div className='flex items-center gap-2'><HiUserCircle className='text-2xl text-[#237cdb]' />
                            <span className='text-[#495463] font-medium'>{false || `None`}</span>
                        </div>
                        <hr className="mt-3 mb-5" />
                        <p className='tracking-widest text-[#758698] text-[12px] font-medium'>ALL REFFERALS</p>
                        <div className='text-[#495463] font-medium text-2xl'>0</div>

                    </div>
                </div>
                <div className='rounded-lg lg:w-2/3 bg-white p-5 pt-4'>
                    <div className='rounded-lg rounded-b-none flex items-center gap-3'>
                        <RxExternalLink className='text-[#253992] text-xl' /> <p className='font-medium text-[#253992] text-xl'>Get new referrals</p>
                    </div>
                    <p className='py-5 -mt-5 text-[14px]'>share your link with your friends and get 5% free bonus</p>
                    <div className='overflow-x-auto'>
                        <table className='w-full'>
                            <thead>
                                <tr>
                                    <td className='font-medium text-gray-600'>Date</td>
                                    <td className='font-medium text-gray-600'>Wallet</td>
                                    <td className='font-medium text-gray-600'>Invest</td>
                                    <td className='font-medium text-gray-600'>Bonus</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='h-10 border border-x-0 duration-300 hover:bg-gray-100/50'>
                                    <td className='text-gray-500 min-w-[105px]'>14 Feb, 23:57</td>
                                    <td className='text-gray-500 min-w-[170px]'>18Dk7usf3SYAugy***</td>
                                    <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                    <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                </tr>
                                <tr className='h-10 border border-x-0 duration-300 hover:bg-gray-100/50'>
                                    <td className='text-gray-500 min-w-[105px]'>14 Feb, 23:57</td>
                                    <td className='text-gray-500 min-w-[170px]'>18Dk7usf3SYAugy***</td>
                                    <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                    <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                </tr>
                                <tr className='h-10 border border-x-0 duration-300 hover:bg-gray-100/50'>
                                    <td className='text-gray-500 min-w-[105px]'>14 Feb, 23:57</td>
                                    <td className='text-gray-500 min-w-[170px]'>18Dk7usf3SYAugy***</td>
                                    <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                    <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Affilates;