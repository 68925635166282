enum UserRoles {
    ADMIN = 'admin',
    USER = 'user',
    MOD = 'mod',
    DEV = 'dev',
}
interface User {
    role: UserRoles
    wallet: string
    refcode: string
    reward: number
    balance: number
    refs: number
    joined: number
    lastClaim: number
    lastAdd: number
    perDay: number
    lastDep: number
    tolDep: number
    lastPay: number
    tolPay: number
    pays: number
    pendingPay: number
    refBonus: number
    lastRef: string | null
    jwt: string
    jwtTime: number
}

const UserTemplate: User = {
    role: UserRoles.USER,
    wallet: '',
    refcode: '',
    reward: 0,
    balance: 0,
    refs: 0,
    joined: 0,
    lastClaim: 0,
    lastAdd: 0,
    perDay: 0,
    lastDep: 0,
    tolDep: 0,
    lastPay: 0,
    tolPay: 0,
    pays: 0,
    pendingPay: 0,
    refBonus: 0,
    lastRef: null,
    jwt: '',
    jwtTime: 0,
}

export { User, UserRoles, UserTemplate };