import { AxiosError } from "axios";
import { Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";
import MyAxiosError from "../types/MyAxiosError";
import { User, UserTemplate } from "../types/User";

const time = (ms: boolean = false): number => parseInt((Date.now() / (ms ? 1 : 1000)).toString());
const getHashRate = (daily: number): string => (daily / 0.000025 * 52).toFixed(2);


const setLsItems = (wallet: string, jwt: string, jwtTime?: number): void => {
    localStorage.setItem('wallet', wallet);
    localStorage.setItem('jwt', jwt);
    if (jwtTime) localStorage.setItem('jwtTime', jwtTime.toString());
}
const getLsItems = (): { wallet: string | null, jwt: string | null } => {
    const jwt = localStorage.getItem("jwt");
    const wallet = localStorage.getItem("wallet");
    return { wallet, jwt };
}

const LogOut = (setUser: Dispatch<SetStateAction<User>>) => {
    removeLsItems();
    setUser(UserTemplate);
}

const removeLsItems = (): void => {
    localStorage.removeItem('wallet');
    localStorage.removeItem('jwt');
    localStorage.removeItem('jwtTime');
}

const handleAxiosError = (error: any, setUser: Dispatch<SetStateAction<User>>) => {
    const e = error as AxiosError;
    if (!e.response?.data) return toast.warn(`Error communicating with server`);

    const r = e.response.data as MyAxiosError;
    if (r.logout) {
        toast.error(r.text || `Session expired, please login again`);
        return LogOut(setUser);
    }

    if (r.refresh) setTimeout(() => window.location.reload(), 300);
    if (!r.ok && r.text) toast.error(r.text);

}

export { time, getHashRate, setLsItems, removeLsItems, getLsItems, handleAxiosError, LogOut };