import React, { FC, useContext } from 'react'
import { BsCurrencyBitcoin, BsFillCalculatorFill } from 'react-icons/bs';
import { AiOutlinePieChart } from 'react-icons/ai';
import { HiInformationCircle } from 'react-icons/hi';
import { GiEmptyHourglass } from 'react-icons/gi';
import { MdOutlineAccountTree } from 'react-icons/md';
import { FaRegArrowAltCircleUp, FaRegArrowAltCircleDown, FaRegChartBar, FaBolt } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Plan from '../../../types/Plan';
import Loading from '../../../utils/components/Loading';
import { MyContext, UserContext } from '../../../App';
import { getHashRate, getLsItems, handleAxiosError, time } from '../../../utils/utils';
import axios from 'axios';


const Dashboard: FC = (): JSX.Element => {
    const { user, setUser, loading, setLoading, setUserLoading } = useContext<UserContext>(MyContext);
    const [plans, setPlans] = useState<Plan[]>([]);
    const [plan, setPlan] = useState<Plan>();
    useEffect(() => (plans[0] && setPlan(plans[0])), [plans]);
    const [reward, setReward] = useState<number>(user?.reward || 0);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setUserLoading(true);
                const { wallet, jwt } = getLsItems();
                if (!jwt || jwt.length !== 50) return;
                const r = await axios.get(`http://localhost:30/v1/users/${wallet}?plans`, { headers: { jwt } });
                if (r.data.user) setUser(r.data.user);
                if (r.data.plans) setPlans(r.data.plans);
            }
            catch (e: any) { handleAxiosError(e, setUser); }
            finally { setUserLoading(false); setLoading(false); }
        })();
    }, []);

    const [mss, setMss] = useState<number>(0);
    useEffect(() => {
        if (!user) return;
        setTimeout(() => {
            setMss(mss + 1);
            setReward(Number((user.perDay / 86400000 * (time(true) - user.lastAdd * 1000)).toFixed(10)));
        }, 100);
    }, [user, mss]);


    if (!user?.wallet || !plan?.perc || loading) return <Loading />
    return (
        <div className='w-[1140px] max-w-[90%] mx-auto'>
            <div className='mt-12 flex flex-col lg:flex-row gap-5'>
                <div className='text-white acc-bal-div p-5 md:p-8 rounded flex flex-col gap-3 relative h-216px] w-full lg:max-w-[350px] overflow-hidden'>
                    <img src="/images/card-right.png" alt="" className='absolute top-0 right-0' />
                    <div className='acc-bal text-white relative flex flex-col xs:flex-row justify-between'>
                        <div className="order-2 xs:order-1">
                            <p className='text-[12px] font-medium tracking-widest text-red-400 xs:text-green-500'>MINING REWARD</p>
                            <p className='text-lg sm:text-2xl font-medium'>{reward.toFixed(10)} <small className='text-sm font-normal'>BTC</small></p>
                            <div className='flex items-center gap-3 mt-1'>
                                <p>$0.00 USD</p>
                                <button
                                    className='shadow-lg rounded tracking-wider text-green-800 bg-green-500 hover:bg-green-600 hover:shadow-none px-2 duration-300 text-[14px]'
                                    onClick={() => console.log('okay!')}
                                >
                                    CLAIM
                                </button>
                            </div>
                        </div>
                        <div className="flex fan relative order-1 xs:order-2 mb-5 mx-auto xs:mb-0 xs:mx-0">
                            <img src="/images/fan1.svg" alt="" className='absolute -top-[2px]' />
                        </div>
                    </div>
                    <div className='flex items-end justify-between'>
                        <div>
                            <p className='text-[#74fffa] text-[12px] font-medium tracking-widest'>YOUR HASHPOWER</p>
                            <p className='text-[20px] font-medium'>{getHashRate(user.perDay)} TH/s</p>
                            <p className='text-xs'>Active Hashpower</p>
                        </div>
                        <div>
                            <p className='text-[20px] font-medium'>{user.perDay.toFixed(8)} BTC</p>
                            <p className='text-xs'>Earnings per day</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col justify-center gap-5 w-full'>
                    <div className='flex flex-col lg:flex-row bg-white px-5 py-3 justify-between gap-5'>
                        <div className='flex gap-2 w-full'>
                            <div className='h-[40px] w-[40px] rounded flex justify-center items-center bal-icon-1'><BsCurrencyBitcoin className='text-white text-xl' /></div>
                            <div>
                                <p className='text-[12px] text-[#8a93b7] font-medium'>Withdrawable Balance</p>
                                <p className='text-[17px] text-[#304073] font-semibold'>{user.balance.toFixed(8)} <span className='text-[#6783b8] text-[17px]'>BTC</span></p>
                            </div>
                        </div>
                        <div className='flex gap-2 w-full'>
                            <div className='h-[40px] w-[40px] rounded flex justify-center items-center bal-icon-2'><FaRegArrowAltCircleDown className='text-white text-xl' /></div>
                            <div>
                                <p className='text-[12px] text-[#8a93b7] font-medium'>Last Investment</p>
                                <p className='text-[17px] text-[#304073] font-semibold'>{user.lastDep.toFixed(8)} <span className='text-[#6783b8] text-[17px]'>BTC</span></p>
                            </div>
                        </div>
                        <div className='flex gap-2 w-full'>
                            <div className='h-[40px] w-[40px] rounded flex justify-center items-center bal-icon-5'><AiOutlinePieChart className='text-white text-xl' /></div>
                            <div>
                                <p className='text-[12px] text-[#8a93b7] font-medium'>Total Investment</p>
                                <p className='text-[17px] text-[#304073] font-semibold'>{user.tolDep.toFixed(8)} <span className='text-[#6783b8] text-[17px]'>BTC</span></p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row bg-white px-5 py-3 justify-between gap-5'>
                        <div className='flex gap-2 w-full'>
                            <div className='h-[40px] w-[40px] rounded flex justify-center items-center bal-icon-3'><FaRegArrowAltCircleUp className='text-white text-xl' /></div>
                            <div>
                                <p className='text-[12px] text-[#8a93b7] font-medium'>Last Payment</p>
                                <p className='text-[17px] text-[#304073] font-semibold'>{user.lastPay.toFixed(8)} <span className='text-[#6783b8] text-[17px]'>BTC</span></p>
                            </div>
                        </div>
                        <div className='flex gap-2 w-full'>
                            <div className='h-[40px] w-[40px] rounded flex justify-center items-center bal-icon-4'><GiEmptyHourglass className='text-white text-xl' /></div>
                            <div>
                                <p className='text-[12px] text-[#8a93b7] font-medium'>Pending Payment</p>
                                <p className='text-[17px] text-[#304073] font-semibold'>{user.pendingPay.toFixed(8)} <span className='text-[#6783b8] text-[17px]'>BTC</span></p>
                            </div>
                        </div>
                        <div className='flex gap-2 w-full'>
                            <div className='h-[40px] w-[40px] rounded flex justify-center items-center bal-icon-6'><FaRegChartBar className='text-[#696460] text-xl' /></div>
                            <div>
                                <p className='text-[12px] text-[#8a93b7] font-medium'>Total Payment</p>
                                <p className='text-[17px] text-[#304073] font-semibold'>{user.tolPay.toFixed(8)} <span className='text-[#6783b8] text-[17px]'>BTC</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='my-10 flex flex-col lg:flex-row w-full gap-5'>
                <div className='rounded-lg border border-[#bcc5d6] lg:w-2/3 acc-bal-2 bg-white'>
                    <div className='rounded-lg rounded-b-none bg-[#235fa7] p-[10px] flex items-center gap-3 px-5'>
                        <MdOutlineAccountTree className='text-white text-xl' /> <p className='text-white font-tello font-medium'>Account Activity</p>
                    </div>
                    <div className='p-[10px] px-5'>
                        You have no activiy
                    </div>
                </div>
                <div className='rounded-lg border border-[#bcc5d6] lg:w-1/3 bg-white'>
                    <div className='rounded-lg rounded-b-none bg-[#235fa7] p-[10px] flex items-center gap-3 px-5'>
                        <BsFillCalculatorFill className='text-white text-xl' /> <p className='text-white font-tello font-medium'>Miner Calculator</p>
                    </div>
                    <div className="m-5 lg:m-8 relative">

                        <select
                            className='w-full border p-2 cursor-pointer outline-none'
                            onChange={(e: any) => setPlan(plans[Number(e.target.value)])}
                        >
                            {plans.map((plan: Plan, i: number) => <option value={i} key={`${i}dsgfwr`}>
                                {plan.name} ({plan.price} BTC)
                            </option>)}
                        </select>

                        <div className='flex items-center mt-5'>
                            <FaBolt className='text-white mr-3 bg-[#ed9633] rounded-full p-1 text-2xl' />
                            <p className='text-[#758698] text-[12px] font-medium tracking-widest'>DAILY PROFITS</p>
                        </div>
                        <div className='flex justify-evenly mt-3'>
                            <div className='flex flex-col items-center'>
                                <div className='border border-[#a4c5f0] text-[14px] font-medium bg-[#e6effb] rounded px-5 py-2 text-[#495463]'>{plan.perc}% Daily</div>
                                <p className='text-[11px] text-gray-500'>Daily Percent</p>
                            </div>
                            <div className='flex flex-col items-center'>
                                <div className='border border-[#a4c5f0] text-[14px] font-medium bg-[#e6effb] rounded px-5 py-2 text-[#495463]'>{getHashRate(plan.daily)} TH/s</div>
                                <p className='text-[11px] text-gray-500'>Mining Hashpower</p>
                            </div>
                        </div>
                        <hr className='my-3' />
                        <p className="text-center text-[20px] text-[#495463] font-medium">{plan.daily.toFixed(8)} BTC</p>
                        <p className='text-[11px] text-gray-500 text-center mb-2'>Daily Income</p>

                        <div className='flex items-center text-[12px] text-[#758698] gap-2 mb-7'>
                            <HiInformationCircle className='text-lg mt-[2px]' />
                            <p>Amount calculated based current btc dificult, price</p>
                        </div>
                        <Link to='/dash/new' className='text-white rounded-md bg-[#2c80ff] w-40 py-3 block text-center font-medium hover:bg-[#005fee] duration-300 mx-auto'>
                            Quick Deposit
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;