import React, { useState, FC, useEffect, useContext } from 'react';
import { CiWallet } from 'react-icons/ci';
import { BsStars } from 'react-icons/bs';
import { FaInfoCircle, FaWallet } from 'react-icons/fa';
import { IoMdPaper } from 'react-icons/io';
import { GiEmptyHourglass, GiWallet } from 'react-icons/gi';
import { useForm } from 'react-hook-form';
import { MyContext, UserContext } from '../../../App';
import Pay from '../../../types/Pay';
import axios from 'axios';
import { handleAxiosError } from '../../../utils/utils';

interface WithdrawData {
    wallet: string,
    amount: number
}

const Withdraw: FC = () => {

    const { user, setUser, setLoading } = useContext<UserContext>(MyContext);
    const [pays, setPays] = useState<Pay[]>([]);
    const [payMethod, setPayMethod] = useState<string>('BTC');

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const r: any = await axios.get('http://localhost:30/v1/pays?user');
                if (r.data?.pays) setPays(r.data.pays);
                if (r.data?.user) setUser(r.data.user);
            }
            catch (e: any) { handleAxiosError(e, setUser); }
            finally { setLoading(false); }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { handleSubmit, register, formState: { errors } } = useForm<WithdrawData>();
    const [balance, setBalance] = useState(0);
    useEffect(() => user && setBalance(user.balance), [user]);

    const onSubmit = async (data: any): Promise<void> => {
        // setBalance(balance - data.amount);
        console.log(data);
    }

    return (
        <div className='w-[1140px] max-w-[90%] mx-auto my-10'>
            <h2 className="text-3xl md:text-4xl font-light font-roboto tracking-wide text-center text-ph">Request a Withdraw</h2>
            <p className="text-pt text-center">Withdraw your account balance to your wallet</p>


            <div className='my-10 flex flex-col lg:flex-row w-full gap-5 justify-between'>
                <div className='rounded-lg lg:w-2/3 bg-white'>
                    <div className='rounded-lg rounded-b-none bg-[#235fa7] p-[10px] flex items-center gap-3 px-5'>
                        <GiWallet className='text-white bg-btc text-4xl p-1 rounded' /> <p className='text-white font-tello font-medium'>Withdraw Earnings</p>
                    </div>

                    {/* withdraw form */}
                    <form onSubmit={handleSubmit(onSubmit)} className='p-4 lg:p-6 flex flex-col gap-5'>
                        <div>
                            <p className='text-gray-500'>Address to send</p>
                            <input type="text" className='w-full px-4 py-2 border border-gray-300 outline-none rounded' placeholder='Enter a BTC address' {...register('wallet', {
                                required: 'BTC address is required',
                                pattern: { value: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/, message: 'Invalid BTC address' }
                            })} />
                            <p className='text-sm text-red-400'>{errors?.wallet?.message ?? ''}</p>
                        </div>
                        <div>
                            <p className='text-gray-500'>
                                Amount <span className='cursor-pointer' onClick={() => { (document.querySelector("#amount") as HTMLInputElement).value = balance.toString(); }}>
                                    (Balance: {balance} BTC)
                                </span>
                            </p>
                            <input type="text" className='w-full px-4 py-2 border border-gray-300 outline-none rounded'
                                id='amount' placeholder='Amount to withdraw' {...register('amount', {
                                    required: 'Amount is required',
                                    min: { value: 0.002, message: 'Minimum 0.002 BTC' },
                                    max: { value: balance, message: 'Not enough balance' },
                                    pattern: { value: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/, message: 'Invalid BTC address' }
                                })} />
                            <p className='text-sm text-red-400'>{errors?.amount?.message}</p>
                        </div>
                        <div>
                            <p className='text-sm font-medium tracking-wide mb-2'>Terms & Conditions <FaInfoCircle className='ml-1 inline-block' /></p>
                            <div className='text-[#2d303a] bg-[#d2dde9] border rounded p-2 border-[#ced3da] flex flex-col gap-2 text-sm'>
                                <div><FaInfoCircle className='mr-1 inline-block mt-[-2px]' />  Requests are processed within 24 hours</div>
                                <div><FaInfoCircle className='mr-1 inline-block mt-[-2px]' />  Min withdraw 0.002 BTC</div>
                            </div>
                        </div>
                        <button className='text-white rounded-md bg-[#2c80ff] w-full py-2 block text-center hover:bg-[#005fee] duration-300'>Withdraw</button>
                    </form>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='text-white acc-bal-div p-5 md:p-8 rounded flex flex-col gap-3 relative h-216px] w-full lg:max-w-[350px] overflow-hidden'>
                        <img src="/images/card-right.png" alt="" className='absolute top-0 right-0' />
                        <div className='acc-bal text-white relative flex justify-between'>
                            <div className="">
                                <p className='text-[#74fffa] text-[12px] font-medium tracking-widest'>MINING REWARD</p>
                                <p className='text-2xl font-medium'>0.00000000 <small className='text-sm font-normal'>BTC</small></p>
                                <div className='flex items-center gap-3 mt-1'>
                                    <p>$0.00 USD</p>
                                    <button className='shadow-lg rounded tracking-wider text-green-800 bg-green-500 hover:bg-green-600 hover:shadow-none px-2 duration-300 text-[14px]'>CLAIM</button>
                                </div>
                            </div>
                            <div className="flex fan relative">
                                <img src="/images/fan1.svg" alt="" className='absolute -top-[2px]' />
                            </div>
                        </div>
                        <div className='flex items-end justify-between'>
                            <div>
                                <p className='text-[#74fffa] text-[12px] font-medium tracking-widest'>YOUR HASHPOWER</p>
                                <p className='text-[20px] font-medium'>0.00 TH/s</p>
                                <p className='text-xs'>Active Hashpower</p>
                            </div>
                            <div>
                                <p className='text-[20px] font-medium'>0.00000000 BTC</p>
                                <p className='text-xs'>Earnings per day</p>
                            </div>
                        </div>
                    </div>
                    <div className='rounded-lg bg-white'>
                        <div className='rounded-lg rounded-b-none bg-[#235fa7] p-[10px] flex items-center gap-3 px-5'>
                            <CiWallet className='text-white text-xl' /> <p className='text-white font-tello font-medium'>Withdraw Stats</p>
                        </div>
                        <div className="m-5 lg:m-8 relative">
                            <p className='tracking-widest text-[#758698] text-[12px] font-medium'>Last Withdraw</p>
                            <div className='flex items-center gap-2'><BsStars className='text-2xl text-gray-500' /> <span className='text-[#495463] font-medium'>0.00000000 BTC</span></div>
                            <hr className="mt-3 mb-5" />
                            <p className='tracking-widest text-[#758698] text-[12px] font-medium'>Pending Withdraw</p>
                            <div className='flex items-center gap-2'><GiEmptyHourglass className='text-2xl text-orange-300' />
                                <span className='text-[#495463] font-medium'>0.00000000 BTC</span>
                            </div>
                            <hr className="mt-3 mb-5" />
                            <p className='tracking-widest text-[#758698] text-[12px] font-medium'>Total Withdraw</p>
                            <div className='flex items-center gap-2'><FaWallet className='text-xl text-green-500' />
                                <span className='text-[#495463] font-medium ml-1'>0.00000000 BTC</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div className='rounded-lg bg-white p-5 pt-4'>
                <div className='rounded-lg rounded-b-none flex items-center gap-3'>
                    <IoMdPaper className='text-[#253992] text-xl' /> <p className='font-medium text-[#253992] text-xl'>Withdrawal List</p>
                </div>
                <p className='py-5 -mt-5 text-[14px]'>Your withdrawal requests</p>
                <div className='overflow-x-auto'>
                    <table className='w-full'>
                        <thead>
                            <tr>
                                <td className='font-medium text-gray-600'>Date</td>
                                <td className='font-medium text-gray-600'>Wallet</td>
                                <td className='font-medium text-gray-600'>Amount</td>
                                <td className='font-medium text-gray-600'>Hash</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='h-10 border border-x-0 duration-300 hover:bg-gray-100/50'>
                                <td className='text-gray-500 min-w-[105px]'>14 Feb, 23:57</td>
                                <td className='text-gray-500 min-w-[170px]'>18Dk7usf3SYAugy***</td>
                                <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                <td className='text-gray-500 min-w-[135px]'>{`Pending`}</td>
                            </tr>
                            <tr className='h-10 border border-x-0 duration-300 hover:bg-gray-100/50'>
                                <td className='text-gray-500 min-w-[105px]'>14 Feb, 23:57</td>
                                <td className='text-gray-500 min-w-[170px]'>18Dk7usf3SYAugy***</td>
                                <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                <td className='text-gray-500 min-w-[135px] text-blue-500 hover:underline'>
                                    <a href="https://blockchain.info/tx/ddfw" target='_blank' rel='noreferrer'>hu4gvy5uv4...</a>
                                </td>
                            </tr>
                            <tr className='h-10 border border-x-0 duration-300 hover:bg-gray-100/50'>
                                <td className='text-gray-500 min-w-[105px]'>14 Feb, 23:57</td>
                                <td className='text-gray-500 min-w-[170px]'>18Dk7usf3SYAugy***</td>
                                <td className='text-gray-500 min-w-[135px]'>0.00000000 BTC</td>
                                <td className='text-gray-500 min-w-[135px]'>{`Pending`}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Withdraw;