import React, { FC, useEffect, useState } from 'react';
import { MdOutlineDashboard } from 'react-icons/md';
import { HiOutlineSparkles } from 'react-icons/hi';
import { HiOutlineCog6Tooth } from 'react-icons/hi2';
import { ImCreditCard } from 'react-icons/im';
import { FaBullhorn } from 'react-icons/fa';
import { RiShieldUserLine } from 'react-icons/ri';
import { Link, Location, Outlet, useLocation } from 'react-router-dom';
import './Dash.css';


const Dash: FC = (): JSX.Element => {
    const [tab, setTab] = useState<string>('dash');
    const location: Location = useLocation();
    useEffect(() => {
        const path = location.pathname;
        if (path === '/dash') return setTab('dash');
        if (path === '/dash/new') return setTab('new');
        if (path === '/dash/withdraw') return setTab('withdraw');
        if (path === '/dash/affiliates') return setTab('affiliates');
        if (path === '/dash/settings') return setTab('settings');
        if (path === '/dash/banners') return setTab('banners');
    }, [location]);

    return (
        <div className='mt-[70px] bg-[#e0e8f3] min-h-screen flex flex-col'>
            <div className='bg-white dash-nav' style={{ boxShadow: '0px 4px 35px 0px rgb(0 0 0 / 10%)' }}>
                <div className='w-[1140px] max-w-[90%] mx-auto gap-7 hidden md:flex'>
                    <Link className={`dash-menu-item gap-3 ${tab === 'dash' ? 'active pointer-events-none !text-gray-500' : ''}`} to='/dash'>
                        <MdOutlineDashboard className='text-xl' /> <p>Dashboard</p>
                    </Link>
                    <Link className={`dash-menu-item gap-3 ${tab === 'new' ? 'active pointer-events-none !text-gray-500' : ''}`} to='/dash/new'>
                        <HiOutlineSparkles className='text-xl' /><p>Buy Hashrate</p>
                    </Link>
                    <Link className={`dash-menu-item gap-3 ${tab === 'withdraw' ? 'active pointer-events-none !text-gray-500' : ''}`} to='/dash/withdraw'>
                        <ImCreditCard className='text-xl' /><p>Withdraw</p>
                    </Link>
                    <Link className={`dash-menu-item gap-3 ${tab === 'affiliates' ? 'active pointer-events-none !text-gray-500' : ''}`} to='/dash/affiliates'>
                        <RiShieldUserLine className='text-xl' /><p>Affiliates</p>
                    </Link>
                    <Link className={`dash-menu-item gap-3 ${tab === 'settings' ? 'active pointer-events-none !text-gray-500' : ''}`} to='/dash/settings'>
                        <HiOutlineCog6Tooth className='text-xl' /><p>Settings</p>
                    </Link>
                    <Link className={`dash-menu-item gap-3 ${tab === 'banners' ? 'active pointer-events-none !text-gray-500' : ''}`} to='/dash/banners'>
                        <FaBullhorn className='text-xl' /><p>Banners</p>
                    </Link>
                </div>
            </div>
            <Outlet />
            <footer className='w-[1140px] max-w-[90%] mx-auto flex justify-between flex-col md:flex-row pb-10 mt-auto'>
                <div className='flex gap-5'>
                    <Link className='text-gray-500 hover:text-blue-400 duration-300' to='/'>FAQ's</Link>
                    <Link className='text-gray-500 hover:text-blue-400 duration-300' to='/'>Privacy Policy</Link>
                    <Link className='text-gray-500 hover:text-blue-400 duration-300' to='/'>Terms of Service</Link>
                </div>
                <p className='text-gray-500'>2023 © All rights reserved</p>
            </footer>
        </div>
    );
};

export default Dash;