import React, { FC } from 'react';

const Settings: FC = () => {
    return (
        <div>
            Settings
        </div>
    );
};

export default Settings;