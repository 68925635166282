import React, { FC } from 'react';
import { MdClose } from 'react-icons/md';
import { RxHamburgerMenu } from 'react-icons/rx';
import { Link } from "react-router-dom";
import './Header.css'

interface Props {
    values: {
        menuExpanded: boolean,
        setMenuExpanded: CallableFunction
    }
}

const Header: FC<Props> = ({ values: { menuExpanded, setMenuExpanded } }) => {

    // const location = useLocation();
    // const [pathName, setPathName] = useState('/');
    // useEffect(() => setPathName(location.pathname), [location]);

    return (
        <div className={`header shadow h-[70px] flex fixed top-0 left-0 z-[9] bg-[#133b8c] w-full px-5 md:px-10`}>
            <div className="navbar flex justify-between w-full max-w-[1140px] mx-auto items-center">
                <Link to='/' className="">
                    <img src="/img/logo-footer.png" alt="logo" />
                </Link>
                <div className="flex-none text-white">
                    <div className='menu-items hidden md:flex gap-8'>
                        <Link to='/' className='relative duration-300 menu-item left-0 hover:left-[5px] font-medium hover:text-yellow-300'>About</Link>
                        <Link to='/' className='relative duration-300 menu-item left-0 hover:left-[5px] font-medium hover:text-yellow-300'>Plans</Link>
                        <Link to='/' className='relative duration-300 menu-item left-0 hover:left-[5px] font-medium hover:text-yellow-300'>Partners</Link>
                        <Link to='/' className='relative duration-300 menu-item left-0 hover:left-[5px] font-medium hover:text-yellow-300'>FAQ</Link>
                    </div>
                    <div onClick={() => setMenuExpanded(!menuExpanded)} className='block md:hidden'>
                        <RxHamburgerMenu className={`text-3xl mr-3 ${menuExpanded && 'hidden'}`} />
                        <MdClose className={`text-3xl mr-3 ${!menuExpanded && 'hidden'}`} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;