import React, { FC, useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import { AiFillThunderbolt } from 'react-icons/ai';
import { TfiBolt } from 'react-icons/tfi';
import { SiLitecoin } from 'react-icons/si';
import { BsFillCalculatorFill, BsFillPatchCheckFill } from 'react-icons/bs';
import { BiChevronDownCircle } from 'react-icons/bi';
import { FaBtc, FaInfoCircle } from 'react-icons/fa';
import { GiNetworkBars } from 'react-icons/gi';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import Loading from '../../../utils/components/Loading';
import Plan from '../../../types/Plan';
import { getHashRate, handleAxiosError } from '../../../utils/utils';
import { MyContext, UserContext } from '../../../App';
import { toast } from 'react-toastify';
import axios from 'axios';


const Buy: FC = (): JSX.Element => {
    const { user, setUser, loading, setLoading } = useContext<UserContext>(MyContext);
    const [plans, setPlans] = useState<Plan[]>([]);
    const [nowPlan, setNowPlan] = useState<Plan>();
    useEffect(() => (plans[0] && setNowPlan(plans[0])), [plans]);
    const [payMethod, setPayMethod] = useState<string>('BTC');
    const navigate: NavigateFunction = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const r: any = await axios.get('http://localhost:30/v1/plans');
                if (r.data?.plans) setPlans(r.data.plans);
            }
            catch (e) { toast.warn(`Error communicating with server`); }
            finally { setLoading(false); }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading || !nowPlan) return <Loading />

    const Continue = async (): Promise<void> => {
        try {
            setLoading(true);
            const r = await axios.post(`http://localhost:30/v1/deps/${user.wallet}`, { plan: nowPlan.num, payMethod }, { headers: { jwt: user.jwt } });
            if (r.data.id) return navigate('/dash/new/' + r.data.id);
        }
        catch (e: any) { handleAxiosError(e, setUser) }
        finally { setLoading(false) }
    }

    return (
        <div className='w-[1140px] max-w-[90%] mx-auto my-10'>
            <h2 className="text-3xl md:text-3xl font-roboto font-light text-center text-[#253992]">Investment Packages</h2>
            <p className="text-center text-[13px] mt-1 text-gray-500">Choose any plan to start mining</p>

            <div className="mx-auto my-7">
                <Swiper modules={[Pagination]} pagination={{ clickable: true, el: '.swiper-pagination' }}
                    breakpoints={{ 0: { slidesPerView: 1 }, 667: { slidesPerView: 2 }, 1024: { slidesPerView: 3 } }}>
                    {plans.map((plan: Plan, i: number) => <SwiperSlide key={i} className='!flex !justify-center'>
                        <div className='mx-3 w-[300px] relative top-0 overflow-hidden'>
                            {(plan.price < plan.oldPrice || plan.bonus) && <div className="badge absolute bg-red-500/90 px-10 py-1 top-4 -right-8 text-white border border-dotted border-white border-2" style={{
                                rotate: '45deg'
                            }}>
                                {plan.price < plan.oldPrice && 'Promo'}
                                {plan.bonus && 'Bonus'}
                            </div>}
                            <div className='bg-[#235fa7] py-2 rounded-md flex justify-center items-center font-medium text-white rounded-b-none gap-2'>
                                <AiFillThunderbolt className='text-white text-xl mt-1' />
                                <p className='text-xl'>{plan.name}</p>
                            </div>
                            <div className={`bg-white p-5 md:p-8 rounded-md rounded-t-none border ${nowPlan.name === plan.name ? 'border-green-600 border-b-4' : ''}`}>
                                <div className="border flex items-end gap-2 justify-center my-[12px] border-[#47a1ef]">
                                    <p className='font-bold font-roboto text-[40px] text-[#45678e]'>{plan.price}</p>
                                    <p className='text-[15px] text-[#E91E63] font-[900] mb-[10px]'>BTC</p>
                                </div>
                                {(plan.price < plan.oldPrice) &&
                                    <div className="border flex items-end gap-2 justify-center my-[12px] border-[#47a1ef] relative scale-50">
                                        <p className='font-bold font-roboto text-[40px] text-[#45678e]'>{plan.oldPrice}</p>
                                        <p className='text-[15px] text-[#E91E63] font-[900] mb-[10px]'>BTC</p>
                                        <div className='border border-red-600 border-dashed rotate-[14deg] top-[29px] w-[110%] absolute'></div>
                                    </div>
                                }
                                <div className='mt-6 flex flex-col font-roboto text-gray-500'>
                                    <div className='flex items-center gap-2'><BsFillPatchCheckFill className='text-green-400 -mt-[1px]' /><p>365 days Contract</p></div>
                                    <div className='flex items-center gap-2'><BsFillPatchCheckFill className='text-green-400 -mt-[1px]' /><p>{plan.perc} % / day</p></div>
                                    <div className='flex items-center gap-2'><BsFillPatchCheckFill className='text-green-400 -mt-[1px]' /><p>{plan.daily} BTC per day</p></div>
                                    <div className='flex items-center gap-2'><BsFillPatchCheckFill className='text-green-400 -mt-[1px]' /><p>{plan.spins} Free spins</p></div>
                                    {plan.bonus && <div className='flex items-center gap-2'><BsFillPatchCheckFill className='text-green-400 -mt-[1px] text-2xl' />
                                        <p className='font-bold'>{plan.bonus} BTC Instant Withdrawable Balance</p>
                                    </div>}
                                </div>
                                <button
                                    className={`block w-full rounded mt-5 text-white py-2 hover:bg-[#1f6fe9] ${nowPlan.name === plan.name ? '!bg-[#454f5d]' : 'bg-[#2c80ff]'}`}
                                    onClick={() => setNowPlan(plan)}>{nowPlan.name === plan.name ? 'Selected' : 'Select Plan'}
                                </button>
                            </div>
                        </div>
                    </SwiperSlide>)}
                </Swiper>
                <div className='swiper-pagination h-10 !relative flex justify-center mt-5 gap-2' />
            </div>
            <div className='rounded-lg border border-[#bcc5d6] bg-white'>
                <div className='rounded-lg rounded-b-none bg-[#235fa7] p-[10px] flex items-center gap-3 px-5 justify-center'>
                    <BsFillCalculatorFill className='text-white text-xl' /> <p className='text-white font-tello font-medium'>Mining Calculator</p>
                </div>

                <div className="mb-5 p-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                    <div className='border border-[#c3c3c3] p-2 flex gap-2 rounded'>
                        <div className='bg-violet rounded text-white w-[40px] h-[40px] flex justify-center items-center'><BiChevronDownCircle className='text-2xl' /></div>
                        <div>
                            <p className='text-[11px] text-[#495463]'>Price</p>
                            <p className='text-[#495463] font-medium'>{nowPlan.price} BTC</p>
                        </div>
                    </div>
                    <div className='border border-[#c3c3c3] p-2 flex gap-2 rounded'>
                        <div className='bg-yellow rounded text-white w-[40px] h-[40px] flex justify-center items-center'><TfiBolt className='text-2xl' /></div>
                        <div>
                            <p className='text-[11px] text-[#495463]'>Hashrate</p>
                            <p className='text-[#495463] font-medium'>{getHashRate(nowPlan.daily)} TH/s</p>
                        </div>
                    </div>
                    <div className='border border-[#c3c3c3] p-2 flex gap-2 rounded'>
                        <div className='bg-green rounded text-white w-[40px] h-[40px] flex justify-center items-center'><FaBtc className='text-2xl' /></div>
                        <div>
                            <p className='text-[11px] text-[#495463]'>Daily Interest</p>
                            <p className='text-[#495463] font-medium'>{nowPlan.perc} %</p>
                        </div>
                    </div>
                    <div className='border border-[#c3c3c3] p-2 flex gap-2 rounded'>
                        <div className='bg-gray rounded text-gray-600 w-[40px] h-[40px] flex justify-center items-center'><IoMdCheckmarkCircleOutline className='text-xl' /></div>
                        <div>
                            <p className='text-[11px] text-[#495463]'>Free Spins</p>
                            <p className='text-[#495463] font-medium'>{nowPlan.spins}</p>
                        </div>
                    </div>
                    <div className='border border-[#c3c3c3] p-2 flex gap-2 rounded'>
                        <div className='bg-gray rounded text-gray-600 w-[40px] h-[40px] flex justify-center items-center'><GiNetworkBars className='text-xl' /></div>
                        <div>
                            <p className='text-[11px] text-[#495463]'>Daily Income</p>
                            <p className='text-[#495463] font-medium'>{nowPlan.daily.toFixed(8)} BTC</p>
                        </div>
                    </div>
                    <div className='border border-[#c3c3c3] p-2 flex gap-2 rounded'>
                        <div className='bg-gray rounded text-gray-600 w-[40px] h-[40px] flex justify-center items-center'><GiNetworkBars className='text-xl' /></div>
                        <div>
                            <p className='text-[11px] text-[#495463]'>Weekly Income</p>
                            <p className='text-[#495463] font-medium'>{(nowPlan.daily * 7).toFixed(8)} BTC</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg-white rounded p-5 mt-8">
                <p className="text-[13px] text-[#1babfee6]">
                    <FaInfoCircle className='inline mr-1 mb-1 ' />Remember, all mining payments will be sent in BITCOIN , to your miner account
                </p>
                <p className='text-[#495463] text-[14px] font-medium my-3'>Select Payment Method</p>
                <div className='flex gap-3 flex-wrap'>
                    <div className={`flex items-center gap-2 border hover:border-[#2c80ff] rounded px-6 py-2 cursor-pointer relative
                        ${payMethod === 'BTC' ? 'border-[#2c80ff]' : 'border-gray-300'}
                    `} onClick={() => setPayMethod('BTC')}>
                        <FaBtc className='text-white bg-btc rounded-full p-1 text-3xl' />
                        <p className='font-medium'>Bitcoin</p>
                        <div className={`${payMethod === 'BTC' ? '' : 'hidden'} w-4 h-4 bg-blue-500 rounded-full border border-white border-2 absolute top-[-8px] right-[-8px]`} />
                    </div>
                    <div className={`flex items-center gap-2 border  hover:border-[#2c80ff] rounded px-6 py-2 cursor-pointer relative
                        ${payMethod === 'LTC' ? 'border-[#2c80ff]' : 'border-gray-300'}
                    `}
                        onClick={() => setPayMethod('LTC')}>
                        <SiLitecoin className='text-silver-400 rounded-full p-1 text-3xl' />
                        <p className='font-medium'>Litecoin</p>
                        <div className={`${payMethod === 'LTC' ? '' : 'hidden'} w-4 h-4 bg-blue-500 rounded-full border border-white border-2 absolute top-[-8px] right-[-8px]`} />
                    </div>
                </div>
                <button className='text-white rounded-md bg-[#2c80ff] w-full py-2 block text-center hover:bg-[#005fee] duration-300 mt-10' onClick={Continue}>Continue</button>
            </div>


        </div>
    );
};

export default Buy;