import React, { FC } from 'react';
import { BsCheck2Circle, BsFillInfoCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const PayDone: FC = () => {
    return (
        <div className='w-[700px] max-w-[90%] mx-auto my-10'>
            <h2 className="text-3xl md:text-4xl font-light font-roboto tracking-wide text-center text-ph mb-10">Purchase Dashboard</h2>
            <div className='rounded-lg overflow-hidden'>
                <div className='bg-[#235494] flex items-center px-3 gap-3'>
                    <BsFillInfoCircleFill className='text-white text-xl' />
                    <p className='text-lg text-white py-2'>Order Confirmation</p>
                </div>
                <div className='bg-white py-10 px-5 lg:px-10 flex flex-col items-center'>
                    <BsCheck2Circle className='text-green-500 text-8xl mb-6' />
                    <p className="text-gray-600 text-xl">Payment Pending to Confirmation</p>
                    <p className='text-gray-500 mt-3'>
                        Your order was processed, as soon as the payment is confirmed in the blockchain network . your hashrate will be activated, Normally take 30 minutes to confirm
                    </p>
                    <Link to='/dash' className='text-white text-center block rounded bg-[#2c80ff] w-40 py-2 font-medium hover:bg-[#005fee] duration-300 mt-10'>Back to Dashboard</Link>
                </div>
            </div>
        </div>
    );
};

export default PayDone;