import axios from 'axios';
import React, { Dispatch, FC, SetStateAction, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AiFillCloseCircle } from 'react-icons/ai';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { MyContext, UserContext } from '../../App';
import { User } from '../../types/User';
import { handleAxiosError, setLsItems } from '../utils';
import './Login.css'

type LoginType = {
    wallet: string
    password: string
}
interface Props {
    values: {
        show: boolean
        setShow: Dispatch<SetStateAction<boolean>>
    }
}
const Login: FC<Props> = ({ values: { show, setShow } }) => {
    const { user, setUser, setLoading, setUserLoading }: UserContext = useContext(MyContext);
    const { register, handleSubmit, formState: { errors } } = useForm<LoginType>();
    const navigate: NavigateFunction = useNavigate();

    useEffect((): void => { (user.wallet && show) && navigate('/dash'); }, [navigate, show, user.wallet]);

    const hideLogin = (): void => {
        document.querySelector(".login__form")?.classList.add('slideUp');
        setTimeout(() => {
            setShow(false)
            document.querySelector(".login__form")?.classList.remove('slideUp');
        }, 300);
    }

    const onSubmit = async ({ wallet, password }: LoginType): Promise<void> => {
        try {
            setLoading(true);
            setUserLoading(true);
            const r = await axios.post('http://localhost:30/v1/login', { wallet, password });
            if (r.data?.user) {
                const user: User = r.data.user;
                setUser(user);
                setLsItems(user.wallet, user.jwt, user.jwtTime);
            }
        }
        catch (e: any) { handleAxiosError(e, setUser); }
        finally { setLoading(false); setUserLoading(false); }
    }

    return (
        <div className={`fixed w-full h-screen z-50 flex justify-center items-center top-0 left-0 backdrop-blur-sm ${!show ? 'hidden' : ''}`}>
            <form className={`w-[400px] max-w-[90%] bg-white p-5 shadow-lg rounded-lg flex flex-col justify-center relative login__form`}
                onSubmit={handleSubmit(onSubmit)}>
                <div className='absolute right-0 -top-24 w-full flex justify-center'><img src="/img/login.png" alt="" className='w-48' /></div>
                <AiFillCloseCircle className='absolute -top-4 -right-4 text-4xl bg-white rounded-full text-red-400 cursor-pointer'
                    onClick={hideLogin}
                />

                <div className='mt-20'>
                    <p className='text-gray-500 text-sm'>Wallet</p>
                    <input type="text" className='outline-none border-2 w-full rounded h-9 px-2 focus:border-lime-500 duration-300' {...register('wallet', {
                        required: 'Bitcoin wallet is required',
                        pattern: { value: /^[a-zA-Z0-9]{32,36}$/, message: 'Invalid Bitcoin Wallet' }
                    })} placeholder='Your Bitcoin Wallet' />
                    <p className="text-xs text-red-400/90">{errors.wallet?.message}</p>
                </div>
                <div>
                    <p className='text-gray-500 text-sm mt-1'>Password</p>
                    <input type="password" className='outline-none border-2 w-full rounded h-9 px-2 focus:border-lime-500 duration-300' {...register('password', {
                        required: 'Password is required',
                        minLength: { value: 6, message: 'Minimum 6 characters' },
                        maxLength: { value: 40, message: 'Maximum 40 characters' },
                    })} placeholder='Your Bitcoin Wallet' />
                    <p className="text-xs text-red-400/90">{errors.password?.message}</p>
                </div>

                <button className='bg-green-500 block w-32 mt-5 rounded h-10 text-white mx-auto text-sm font-bold tracking-wider hover:bg-green-600 duration-300'>CONTINUE</button>

            </form>
        </div>
    );
};

export default Login;