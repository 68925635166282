import React, { Children, ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { User } from "../types/User";
import Loading from "./components/Loading";

interface Props {
    values: {
        user: User,
        userLoading: boolean
    },
    children: ReactElement;
}

const RequireUser = ({ values: { user, userLoading }, children }: Props): ReactElement => {
    if (userLoading) return <Loading />;
    if (!user.wallet) return <Navigate to='/' />
    return Children.only(children);
}

export { RequireUser };
