import React, { FC } from 'react';
import { FaBolt, FaSpinner } from 'react-icons/fa';
import { IoWalletOutline } from 'react-icons/io5';
import { GiNetworkBars } from 'react-icons/gi';
import { MdOutlineFileCopy } from 'react-icons/md';
import { TfiReceipt } from 'react-icons/tfi';
import { SiVirustotal } from 'react-icons/si';
import PayDone from './PayDone';

const PayPending: FC = () => {

    const onSubmit = async (): Promise<void> => {
        console.log('okayyyyyyyy');
    }

    if (Math.random() > 0.5) return <PayDone />;
    return (
        <div className='w-[825px] max-w-[90%] mx-auto my-10'>
            <h2 className="text-3xl md:text-4xl font-light font-roboto tracking-wide text-center text-ph">Send the Payment</h2>
            <p className="text-pt text-center">Please Complete the payment to continue</p>

            <div className='mb-10 mt-6 bg-white pt-10 px-5 lg:px-10 flex flex-col md:flex-row justify-between gap-8 items-center'>
                <div className='w-full lg:w-2/3 order-2 md:order-1'>
                    <p className="font-medium text-[15px] text-[#253992] mb-3">Please send exact amount (Bitcoin)</p>
                    <div className="relative">
                        <input type="text" className='text-[#3e3737] font-medium text-[21px] bg-[#f2f5f9] border border-[#ced3da] pl-3 py-1 w-full outline-none'
                            value='0.50500000' readOnly
                        />
                        <span className='absolute top-[2px] right-[6px] group bg-[#e9eff9] p-2 hover:bg-[#2c80ff] duration-300 cursor-pointer rounded'>
                            <MdOutlineFileCopy className='text-[#758698] group-hover:text-white text-xl' />
                        </span>
                    </div>
                    <p className="text-xs mt-4 mb-2">To the following Address</p>
                    <div className="relative">
                        <input className='w-full border border-[#d2dde9] rounded-[4px] h-12 duration-300 flex pr-[50px] pl-3 outline-none text-[#495463]'
                            type="text" value='3R1Gi2Jc9yAwHmvVXUFVYbhgMxCFbBdsK23R1Gi2Jc9yAwHmvVXUFVYbhgMxCFbBdsK2' readOnly
                        />
                        <span className='absolute top-[6px] right-[6px] group bg-[#e9eff9] p-2 hover:bg-[#2c80ff] duration-300 cursor-pointer rounded'>
                            <MdOutlineFileCopy className='text-[#758698] group-hover:text-white text-xl' />
                        </span>
                    </div>
                    <p className='mt-6 text-xs  text-gray-500'>Your payment will be confirmed after 3-4 network confirmations.</p>
                    <p className='text-[#28a745] text-sm mt-2'>Waiting for Payment <FaSpinner className='inline text-lg ml-1 -mt-[1px] spinning' /></p>

                    <div className='my-10 flex gap-5'>
                        <button className='text-white rounded-md bg-[#2c80ff] w-40 py-2 font-medium hover:bg-[#005fee] duration-300' onClick={onSubmit}>I Already Paid</button>
                        <button className='text-[#253992] rounded-md bg-white w-40 py-2 font-medium border border-[#253992]'>Go Back</button>
                    </div>
                </div>
                <div className='order-1 md:order-2 w-full lg:w-1/3 flex flex-col justify-center items-center'>
                    <img src={`//api.qrserver.com/v1/create-qr-code/?color=000000&bgcolor=FFFFFF&data=3R1Gi2Jc9yAwHmvVXUFVYbhgMxCFbBdsK2&qzone=1&margin=0&size=150x150&ecc=L`} alt="" />
                    <p className='text-gray-500 text-sm'>Scan qr code to pay order</p>
                </div>
            </div>

            <div>
                <div className='bg-[#235494] flex items-center px-3 gap-3'>
                    <TfiReceipt className='text-white text-xl' />
                    <p className='text-lg text-white py-2'>Order Summary</p>
                </div>
                <div className='bg-white grid grid-cols-2 md:grid-cols-4 gap-3 py-5 px-5 lg:px-10'>
                    <div>
                        <div className='text-gray-500 flex items-center gap-2'><FaBolt /> <p className='text-sm font-medium'>HASHRATE</p></div>
                        <div className='text-[15px] text-[#495463] font-bold mt-1 ml-1'>12300 TH/s</div>
                    </div>
                    <div>
                        <div className='text-gray-500 flex items-center gap-2'><GiNetworkBars /> <p className='text-sm font-medium'>Daily Percent</p></div>
                        <div className='text-[15px] text-[#495463] font-bold mt-1 ml-1'>3 % Daily</div>
                    </div>
                    <div>
                        <div className='text-gray-500 flex items-center gap-2'><IoWalletOutline className='text-lg' /> <p className='text-sm font-medium'>Daily Income</p></div>
                        <div className='text-[15px] text-[#495463] font-bold mt-1 ml-1'>0.00000000 BTC</div>
                    </div>
                    <div>
                        <div className='text-gray-500 flex items-center gap-2'><SiVirustotal className='text-lg' /> <p className='text-sm font-medium'>Total Spins</p></div>
                        <div className='text-[15px] text-[#495463] font-bold mt-1 ml-1'>15</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayPending;