import React, { FC } from 'react';

const Banners:FC = () => {
    return (
        <div>
            Banners
        </div>
    );
};

export default Banners;